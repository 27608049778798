import request from '../util/request'

const Notify = {
    domainUrl: process.env.REACT_APP_NOFIFY_API,
    version: '/v1',
    baseUrl: function () {
        return this.domainUrl + this.version;
    },
};
export const getTeamsUserByUserId = ({
    logId = "",
    userId = ""
                                     } = {}) => {
    return request({
        url: `/microsoft/teams/log/${logId}/user`,
        baseURL: Notify.baseUrl(),
        method: 'get',
        params: {
            userId,
        },
    });
}
export const createTeamsUser = () => {
    return request({
        url: '/microsoft/teams/users',
        baseURL: Notify.baseUrl(),
        method: 'post',
    });

}

export const addTeamsUserToThread =
    ({
         threadId = "",
         msUserId = "",
         userName = "dotzero",
     } = {}) => {
        return request({
            url: '/microsoft/teams/threads/add-user',
            baseURL: Notify.baseUrl(),
            method: 'post',
            data: {
                threadId,
                userName,
                msUserId
            }
        });

    }
export function fetchThreadIdByLogId({ logId = '' } = {}) {
    return request({
        url: `/microsoft/teams/threads`,
        params: {
            logId,
        },
        baseURL: Notify.baseUrl(),
        method: 'get',
    })
}
