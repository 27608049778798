import Cookies from 'js-cookie';
import {getCustomerInfo} from "../api/customer";

// const TokenKey = 'Admin-Token'
const TokenKey = 'token';
const RefreshTokenKey = 'refreshToken';
const UserID = 'tenantID';
const UserKey = 'user'

export async function getUserByToken() {
    try {
        const userInfo = await getCustomerInfo()
        console.log('user info', userInfo)
        return userInfo.data
    } catch (e) {
        console.error('get user name by token', e)
    }
    return ""
}

export function getToken() {
    return Cookies.get(TokenKey);
}

export function getRefreshToken() {
    return Cookies.get(RefreshTokenKey);
}

export function setToken(token) {
    return Cookies.set(TokenKey, token);
}


export function getUserID() {
    return Cookies.get(UserID);
}

export function setUserID(ID) {
    return Cookies.set(UserID, ID, {
        expires: 7,
    });
}

export function getUserInfo() {
    return Cookies.get(UserKey);
}
