import React from "react";
import GetUUID from "uuid-by-string"
import {CallClient, LocalVideoStream, Features} from '@azure/communication-calling';
import {AzureCommunicationTokenCredential} from '@azure/communication-common';
import {Stack, MessageBar, MessageBarType, IconButton, OverflowSet} from '@fluentui/react';
import { Icon } from '@fluentui/react/lib/Icon';
import IncomingCallCard from './IncomingCallCard';
import CallCard from '../MakeCall/CallCard'
import ChatComponents from "../../../ChatComponentsStateful";
import {Dialog, DialogFooter} from "@fluentui/react/lib/Dialog";
import {DefaultButton, PrimaryButton} from "@fluentui/react/lib/Button";
import {fetchLogById, fetchUsersById} from "../../../api/log";
import {testNotify} from "../../../api/notify";

export default class MakeCall extends React.Component {
    constructor(props) {
        super(props);
        const {
            userId,
            userToken,
            userDisplayName,
            userClientTag,
            chatThreadClient,
            hasHistoryStatus,
            previousMessage,
            threadId,
            logId,
            autoJoinMeetingRoom,
            setAutoJoinMeetingRoom,
        } = props
        this.chatThreadClient = chatThreadClient
        this.autoJoinMeetingRoom = autoJoinMeetingRoom
        this.setAutoJoinMeetingRoom = setAutoJoinMeetingRoom
        this.tenantId = null
        this.callClient = null;
        this.callAgent = null;
        this.deviceManager = null;
        this.destinationUserIds = null;
        this.destinationPhoneIds = null;
        this.destinationGroup = null;
        this.meetingLink = null;
        this.meetingId = null;
        this.threadId = threadId;
        this.logId = logId
        this.messageId = null;
        this.organizerId = null;
        this.callError = null;
        this.logBuffer = [];

        this.state = {
            userId: userId,
            userToken: userToken,
            userDisplayName: userDisplayName,
            userClientTag: userClientTag,
            previousMessage: previousMessage,
            hasHistoryStatus: hasHistoryStatus,
            id: undefined,
            loggedIn: false,
            call: undefined,
            incomingCall: undefined,
            showCallSampleCode: false,
            showMuteUnmuteSampleCode: false,
            showHoldUnholdCallSampleCode: false,
            selectedCameraDeviceId: null,
            selectedSpeakerDeviceId: null,
            selectedMicrophoneDeviceId: null,
            deviceManagerWarning: null,
            callError: null,
            ufdMessages: [],
            broadcastInviteLinkToUserDialogHidden: true,
        };

        setInterval(() => {
            if (this.state.ufdMessages.length > 0) {
                this.setState({ufdMessages: this.state.ufdMessages.slice(1)});
            }
        }, 10000);
    }

    componentDidMount() {
        this.handleLogIn()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.call !== this.state.call || prevState.loggedIn !== this.state.loggedIn) {
            if (!this.state.call && this.state.loggedIn && this.props.autoJoinMeetingRoom) {
                this.placeCall(false)
                this.setAutoJoinMeetingRoom(false)
            }

        }
    }

    handleLogIn = async ({
                             id = this.state.userId,
                             token = this.state.userToken,
                             displayName = this.state.userDisplayName,
                             clientTag = this.state.userClientTag,
                         } = {}) => {
        if (token) {
            try {
                const _this = this
                const tokenCredential = new AzureCommunicationTokenCredential(
                    token
                );
                this.callClient = new CallClient(
                    {
                        diagnostics: {
                            appName: 'azure-communication-services',
                            appVersion: '1.3.1-beta.1',
                            tags: ["javascript_calling_sdk", `#clientTag:${clientTag}`]
                        }
                    }
                );
                this.callAgent = await this.callClient.createCallAgent(tokenCredential, {displayName: displayName});

                window.callAgent = this.callAgent;

                this.deviceManager = await this.callClient.getDeviceManager();
                await this.deviceManager.askDevicePermission({audio: true});
                await this.deviceManager.askDevicePermission({video: true});
                this.callAgent.on('callsUpdated', e => {
                    console.log(`callsUpdated, added=${e.added}, removed=${e.removed}`);

                    e.added.forEach(call => {
                        this.setState({call: call});

                        const diagnosticChangedListener = (diagnosticInfo) => {
                            const rmsg = `UFD Diagnostic changed:
                            Diagnostic: ${diagnosticInfo.diagnostic}
                            Value: ${diagnosticInfo.value}
                            Value type: ${diagnosticInfo.valueType}`;
                            if (this.state.ufdMessages.length > 0) {
                                this.setState({ufdMessages: [...this.state.ufdMessages, rmsg]});
                            } else {
                                this.setState({ufdMessages: [rmsg]});
                            }


                        };

                        call.feature(Features.UserFacingDiagnostics).media.on('diagnosticChanged', diagnosticChangedListener);
                        call.feature(Features.UserFacingDiagnostics).network.on('diagnosticChanged', diagnosticChangedListener);
                    });

                    e.removed.forEach(call => {
                        if (this.state.call && this.state.call === call) {
                            this.displayCallEndReason(this.state.call.callEndReason);
                        }
                    });
                });
                this.callAgent.on('incomingCall', args => {
                    const incomingCall = args.incomingCall;
                    if (this.state.call) {
                        incomingCall.reject();
                        return;
                    }

                    this.setState({incomingCall: incomingCall});

                    incomingCall.on('callEnded', args => {
                        this.displayCallEndReason(args.callEndReason);
                    });
                });
                this.setState({loggedIn: true});
            } catch (e) {
                console.error(e);
            }
        }
    }

    displayCallEndReason = (callEndReason) => {
        if (callEndReason.code !== 0 || callEndReason.subCode !== 0) {
            this.setState({callError: `Call end reason: code: ${callEndReason.code}, subcode: ${callEndReason.subCode}, all reason: ${JSON.stringify(callEndReason)}`});
        }

        this.setState({call: null, incomingCall: null});
    }

    getMeetingLink = ({
                          logId = this.logId,
                          userId = "",
                      } = {}) => {
        let link = ''
        link = `${process.env.REACT_APP_FRONTEND_URL}/${logId}?autoJoinTeamsMeetingRoom=true`
        if (userId) {
            link += `&userId=${encodeURI(userId)}`
        }
        return link
    }

    broadcastInviteLinkToAllUser = async () => {
        console.log('log id', this.logId)
        console.log('broadcastInviteToAllUser')
        const {data: logData} = (await fetchLogById(this.logId)).data
        let log = {
            title: {
                plain: ""
            }
        }
        if (logData && logData.length) {
            log.title.plain = logData[0].title.plain
        }


        const users = (await fetchUsersById(this.logId)).data
        console.log('users', users)
        console.log('log data', log.title.plain)
        for (let i = 0; i < users.length; i++) {
            const user = users[i]
            const _userId = user.id
            const subject = `視訊通知`
            let message = ''
            message = `視訊連結\n${log.title.plain}\n${this.getMeetingLink({logId: this.logId, userId: _userId})}`
            const data = {
                Message: {
                    logId: this.logId,
                    ruleId: '',
                    emailTitle: subject,
                    emailContent: message,
                    smsContent: message,
                    expoTitle: subject,
                    expoContent: message,
                    expoAdditionalInformation: {
                        openTab: "teams",
                        autoJoinTeamsMeetingRoom: true,
                    },
                    lineContent: message,
                    teamsEchoBotContent: message,
                    teamsEchoBotAdditionalInformation: {}
                },
                Type: 'Notification',
            }
            console.log(data)
            try {
                await testNotify(data, _userId)
            } catch (e) {
                console.error(e)
            }
        }
    }

    placeCall = async (withVideo) => {
        try {

            const callOptions = await this.getCallOptions(withVideo);

            const uuidByThreadId = GetUUID(this.threadId, 3)
            this.callAgent.join({groupId: uuidByThreadId}, callOptions)

        } catch (e) {
            console.error('Failed to place a call', e);
            this.setState({callError: 'Failed to place a call: ' + e});
        }
    };
    inviteAllUser = async () => {
        // console.log('this',this)
        console.log('remote parti=>', this.state.call.remoteParticipants.map(participant => participant.identifier.communicationUserId))
        const participants = this.props.chatThreadClient.listParticipants();
        for await (const participant of participants) {
            console.log('participant=>', participant)
            // if (participant.id.communicationUserId !== this.state.userId) {
            if (![
                this.state.userId,
                ...this.state.call.remoteParticipants.map(participant => participant.identifier.communicationUserId)
            ].includes(participant.id.communicationUserId)) {
                this.state.call.addParticipant(participant.id);
            }
        }
    }

    async getCallOptions(withVideo) {
        let callOptions = {
            videoOptions: {
                localVideoStreams: undefined
            },
            audioOptions: {
                muted: false
            }
        };

        let cameraWarning = undefined;
        let speakerWarning = undefined;
        let microphoneWarning = undefined;

        // On iOS, device permissions are lost after a little while, so re-ask for permissions
        await this.deviceManager.askDevicePermission({video: true});
        await this.deviceManager.askDevicePermission({audio: true});

        const cameras = await this.deviceManager.getCameras();
        const cameraDevice = cameras[0];
        if (cameraDevice && cameraDevice?.id !== 'camera:') {
            this.setState({
                selectedCameraDeviceId: cameraDevice?.id,
                cameraDeviceOptions: cameras.map(camera => {
                    return {key: camera.id, text: camera.name}
                })
            });
        }
        if (withVideo) {
            try {
                if (!cameraDevice || cameraDevice?.id === 'camera:') {
                    throw new Error('No camera devices found.');
                } else if (cameraDevice) {
                    callOptions.videoOptions = {localVideoStreams: [new LocalVideoStream(cameraDevice)]};
                }
            } catch (e) {
                cameraWarning = e.message;
            }
        }

        try {
            const speakers = await this.deviceManager.getSpeakers();
            const speakerDevice = speakers[0];
            if (!speakerDevice || speakerDevice.id === 'speaker:') {
                throw new Error('No speaker devices found.');
            } else if (speakerDevice) {
                this.setState({
                    selectedSpeakerDeviceId: speakerDevice.id,
                    speakerDeviceOptions: speakers.map(speaker => {
                        return {key: speaker.id, text: speaker.name}
                    })
                });
                await this.deviceManager.selectSpeaker(speakerDevice);
            }
        } catch (e) {
            speakerWarning = e.message;
        }

        try {
            const microphones = await this.deviceManager.getMicrophones();
            const microphoneDevice = microphones[0];
            if (!microphoneDevice || microphoneDevice.id === 'microphone:') {
                throw new Error('No microphone devices found.');
            } else {
                this.setState({
                    selectedMicrophoneDeviceId: microphoneDevice.id,
                    microphoneDeviceOptions: microphones.map(microphone => {
                        return {key: microphone.id, text: microphone.name}
                    })
                });
                await this.deviceManager.selectMicrophone(microphoneDevice);
            }
        } catch (e) {
            microphoneWarning = e.message;
        }

        if (cameraWarning || speakerWarning || microphoneWarning) {
            this.setState({
                deviceManagerWarning:
                    `${cameraWarning ? cameraWarning + ' ' : ''}
                    ${speakerWarning ? speakerWarning + ' ' : ''}
                    ${microphoneWarning ? microphoneWarning + ' ' : ''}`
            });
        }

        return callOptions;
    }

    render() {
        // TODO: Create section component. Couldnt use the ExampleCard compoenent from uifabric becuase its buggy,
        //       when toggling their show/hide code functionality, videos dissapear from DOM.

        return (
            <div>
                <Dialog
                    hidden={this.state.broadcastInviteLinkToUserDialogHidden}
                    dialogContentProps={{
                        title: '是否發送會議邀約給相關人員',
                    }}
                >
                    <DialogFooter>
                        <DefaultButton
                            onClick={() => {
                                this.setState({
                                    broadcastInviteLinkToUserDialogHidden: true,
                                })
                            }}
                        >
                            No
                        </DefaultButton>
                        <PrimaryButton
                            onClick={async () => {
                                await this.broadcastInviteLinkToAllUser()
                                this.setState({
                                    broadcastInviteLinkToUserDialogHidden: true,
                                })
                            }}
                        >
                            Yes
                        </PrimaryButton>
                    </DialogFooter>
                </Dialog>
                <div>
                    <Stack>
                        {
                            this.state.callError &&
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={false}
                                onDismiss={() => {
                                    this.setState({callError: undefined})
                                }}
                                dismissButtonAriaLabel="Close">
                                <b>{this.state.callError}</b>
                            </MessageBar>
                        }
                        {
                            this.state.deviceManagerWarning &&
                            <MessageBar
                                messageBarType={MessageBarType.warning}
                                isMultiline={false}
                                onDismiss={() => {
                                    this.setState({deviceManagerWarning: undefined})
                                }}
                                dismissButtonAriaLabel="Close">
                                <b>{this.state.deviceManagerWarning}</b>
                            </MessageBar>
                        }
                        {
                            this.state.ufdMessages.length > 0 &&
                            <MessageBar
                                messageBarType={MessageBarType.warning}
                                isMultiline={true}
                                onDismiss={() => {
                                    this.setState({ufdMessages: []})
                                }}
                                dismissButtonAriaLabel="Close">
                                {this.state.ufdMessages.map(msg => <li>{msg}</li>)}
                            </MessageBar>
                        }
                        {
                            this.state.incomingCall && !this.state.call &&
                            <IncomingCallCard
                                incomingCall={this.state.incomingCall}
                                acceptCallOptions={async () => await this.getCallOptions()}
                                acceptCallWithVideoOptions={async () => await this.getCallOptions(true)}
                                onReject={() => {
                                    this.setState({incomingCall: undefined})
                                }}/>
                        }
                        {
                            this.state.call ?
                                <CallCard
                                    getMeetingLink={this.getMeetingLink}
                                    userId={this.state.userId}
                                    chatThreadClient={this.chatThreadClient}
                                    call={this.state.call}
                                    deviceManager={this.deviceManager}
                                    selectedCameraDeviceId={this.state.selectedCameraDeviceId}
                                    cameraDeviceOptions={this.state.cameraDeviceOptions}
                                    speakerDeviceOptions={this.state.speakerDeviceOptions}
                                    microphoneDeviceOptions={this.state.microphoneDeviceOptions}
                                    onShowCameraNotFoundWarning={(show) => {
                                        this.setState({showCameraNotFoundWarning: show})
                                    }}
                                    onShowSpeakerNotFoundWarning={(show) => {
                                        this.setState({showSpeakerNotFoundWarning: show})
                                    }}
                                    onShowMicrophoneNotFoundWarning={(show) => {
                                        this.setState({showMicrophoneNotFoundWarning: show})
                                    }}/> :
                                <ChatComponents
                                    previousMessage={this.props.previousMessage}
                                    hasMessage={this.props.hasHistoryStatus}
                                    extra={
                                        !this.state.incomingCall && !this.state.call &&
                                        <Stack horizontal disableShrink>
                                            <IconButton
                                                iconProps={{
                                                    iconName: 'Warning12',
                                                    style: {verticalAlign: 'middle', fontSize: 'large'}
                                                }}
                                                text="Place call"
                                                style={{display: !this.state.call && this.state.loggedIn ? "none" : null}}
                                                onClick={() => {
                                                    window.open(window.location.href)
                                                    // this.handleLogIn()
                                                }}>
                                            </IconButton>
                                            <IconButton
                                                iconProps={{
                                                    iconName: 'Phone',
                                                    style: {verticalAlign: 'middle', fontSize: 'large'}
                                                }}
                                                text="Place call"
                                                disabled={this.state.call || !this.state.loggedIn}
                                                onClick={() => {
                                                    this.setState({
                                                        broadcastInviteLinkToUserDialogHidden: false,
                                                    })
                                                    this.placeCall(false)
                                                }}>
                                            </IconButton>
                                            <IconButton
                                                iconProps={{
                                                    iconName: 'Video',
                                                    style: {verticalAlign: 'middle', fontSize: 'large'}
                                                }}
                                                text="Place call with video"
                                                disabled={this.state.call || !this.state.loggedIn}
                                                onClick={() => {
                                                    this.setState({
                                                        broadcastInviteLinkToUserDialogHidden: false,
                                                    })
                                                    this.placeCall(true)
                                                }}>
                                            </IconButton>
                                        </Stack>
                                    }
                                />

                        }
                    </Stack>
                </div>
            </div>
        );
    }
}
