import React, {useEffect, useState} from 'react'
import {useLocation, useParams} from "react-router-dom";
import {ChatClient} from "@azure/communication-chat";
import {AzureCommunicationTokenCredential} from "@azure/communication-common";
import {Dialog, DialogFooter} from '@fluentui/react/lib/Dialog';
import {TextField} from '@fluentui/react/lib/TextField';
import {PrimaryButton} from '@fluentui/react/lib/Button';
import {addTeamsUserToThread, createTeamsUser, fetchThreadIdByLogId, getTeamsUserByUserId} from "./api/teams";
import {
    ChatClientProvider,
    ChatThreadClientProvider,
    createStatefulChatClient,
    FluentThemeProvider
} from "@azure/communication-react";
import Loading from "./components/loading/loading";
import MakeCall from "./components/teams-call/MakeCall/MakeCall";
import qs from "query-string";
import {getUserByToken, getUserNameByToken} from "./util/auth";
import {getUserById} from "./api/user";
import TextFieldWithComposition from "./components/text-field-with-composition/text-field-with-composition";

const endpointUrl = 'https://teams-com.communication.azure.com';
const ChatRoomByThreadId = () => {
    const location = useLocation();
    const {logId} = useParams()
    const [threadId, setThreadId] = useState("")
    const [userId, setUserId] = useState("")
    const [chatThreadClient, setChatThreadClient] = useState({})
    const [getHistoryStatus, setGetHistoryStatus] = useState(true)
    const [hasHistoryStatus, setHasHistoryStatus] = useState(false)
    const [previousMessage, setPreviousMessage] = useState([])
    const [statefulChatClient, setStatefulChatClient] = useState({})
    const [msUserId, setMsUserId] = useState()
    const [userInfoDialogHidden, setUserInfoDialogHidden] = useState(true)
    const [userName, setUserName] = useState('')
    const [userToken, setUserToken] = useState('')
    const [autoJoinMeetingRoom, setAutoJoinMeetingRoom] = useState(false)

    async function joinToThread() {
        const _tokenCredential = new AzureCommunicationTokenCredential(userToken);
        let chatClient = new ChatClient(endpointUrl, _tokenCredential)

        const _statefulChatClient = await createStatefulChatClient({
            userId: {kind: 'communicationUser', communicationUserId: msUserId},
            displayName: userName,
            endpoint: endpointUrl,
            credential: _tokenCredential
        });
        console.log('join to thread', userName)

        const _chatThreadClient = await chatClient.getChatThreadClient(threadId);

        //Listen to notifications
        await _statefulChatClient.startRealtimeNotifications();
        _statefulChatClient.on("chatMessageReceived", e => {
            setPreviousMessage(olderVal => {
                    return [...olderVal, parseNowMessage(e)]
                    // return [...olderVal, e]
                }
            )
        })
        setStatefulChatClient(_statefulChatClient)
        setChatThreadClient(_chatThreadClient)

        // const participants = _chatThreadClient.listParticipants();
        // for await (const participant of participants) {
        //     console.log('participant=>', participant)
        // }
    }


    async function getHistoryMessageList() {
        const messages = chatThreadClient.listMessages();
        for await (const message of messages) {
            if (message.type === 'text') {
                if (!hasHistoryStatus) {
                    console.log('has message')
                    setHasHistoryStatus(true)
                }
                setPreviousMessage(olderVal => {
                        return [parseHistoryMessage(message), ...olderVal]
                    }
                )
                // setPreviousMessage(olderVal => {
                //         return [message, ...olderVal]
                //     }
                // )
            }
        }
        console.log('message length', messages)
    }

    // function utf8_to_b64( str ) {
    //     return btoa(encodeURIComponent( escape( str )));
    // }
    function parseNowMessage(input) {
        console.log('parse now Message', JSON.stringify(input))
        return {
            messageType: 'chat',
            senderId: input.sender.communicationUserId,
            senderDisplayName: input.senderDisplayName,
            messageId: Math.random().toString(),
            content: input.message,
            createdOn: input.createdOn,
            mine: input.sender.communicationUserId === msUserId,
            attached: false,
            status: 'seen',
            contentType: 'text'
        }
    }

    function parseHistoryMessage(input) {
        console.log('parse history message', input)
        return {
            messageType: 'chat',
            senderId: input.sender.communicationUserId,
            senderDisplayName: input.senderDisplayName,
            messageId: Math.random().toString(),
            content: input.content.message,
            createdOn: input.createdOn,
            mine: input.sender.communicationUserId === msUserId,
            attached: false,
            status: 'seen',
            contentType: 'text'
        }
    }

    async function joinMeetRoom({
                                    _threadId = threadId,
        _userId = userId
                                } = {}) {
        const {msUserId: msUserId, token: _token, status: _status} = (await getTeamsUserByUserId({
            logId: logId,
            userId: _userId,
        })).data
        // const {msUserId: msUserId, token: _token, status: _status} = (await createTeamsUser()).data
        console.log('ms user id', msUserId)
        switch (_status) {
            case "old":
                break
            case "new":
                await addTeamsUserToThread({
                    threadId: _threadId,
                    msUserId: msUserId,
                    userName: userName
                })
                break
            default: {

            }
        }
        setUserToken(_token)
        setMsUserId(msUserId)
        setUserInfoDialogHidden(true)
    }

    useEffect(() => {
        async function exec() {
            const {data} = await fetchThreadIdByLogId({logId: logId})
            let _userName = ""
            const {userId: _userId, autoJoinTeamsMeetingRoom: _autoJoinMeetingRoom} = qs.parse(location.search)
            try {
                const userData = (await getUserById({
                    userId: _userId,
                })).data
                console.log('user data', userData)
                if (userData && userData.length) {
                    _userName = userData[0].user
                }
            } catch (e) {
                console.log(e)
            }
            console.log('thread id', data)
            setThreadId(data.threadId)
            console.log('react router dom', location)
            const userByToken = await getUserByToken()
            console.log('user by token', userByToken)
            setAutoJoinMeetingRoom(_autoJoinMeetingRoom)
            if (userByToken && userByToken.name) {
                setUserId(userByToken.adminEmail)
                setUserName(userByToken.name)
                await joinMeetRoom({
                    _threadId: data.threadId,
                    _userId: userByToken.adminEmail,
                })
            } else {
                setUserId(_userId)
                setUserName(_userName)
                setUserInfoDialogHidden(false)
            }
        }

        exec()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (msUserId) {
            async function exec() {
                await joinToThread()
                setGetHistoryStatus(false)
            }

            exec()
        }
        // eslint-disable-next-line
    }, [msUserId])

    useEffect(() => {
        if (!getHistoryStatus) {
            // getHistoryMessageList()
            function exec() {
                setTimeout(async function () {
                    try {
                        setGetHistoryStatus(true)
                        await getHistoryMessageList()
                    } catch (err) {
                        setGetHistoryStatus(false)
                    }
                }, 2000)
            }

            exec()
        }
        // eslint-disable-next-line
    }, [
        getHistoryStatus
    ])
    return (
        <FluentThemeProvider>
            <ChatClientProvider chatClient={statefulChatClient}>
                <ChatThreadClientProvider chatThreadClient={chatThreadClient}>
                    <Dialog
                        hidden={userInfoDialogHidden}
                    >

                        加入會議室前，再次確認您的姓名是否正確
                        <TextFieldWithComposition
                            label={"User Name"}
                            value={userName}
                            onChange={(e, newString) => {
                                setUserName(newString)
                            }}
                            onSubmit={(value) => {
                                console.log('on submit', value)
                                setUserName(value)
                                joinMeetRoom()
                            }}
                        />
                        <DialogFooter>
                            <PrimaryButton
                                onClick={joinMeetRoom}
                            >加入會議室</PrimaryButton>
                        </DialogFooter>
                    </Dialog>
                    {/*<Loading />*/}
                    {Object.keys(statefulChatClient).length &&
                    Object.keys(chatThreadClient).length ?
                        <>
                            <MakeCall
                                userId={msUserId}
                                userToken={userToken}
                                userDisplayName={userName}
                                userClientTag={''}
                                chatThreadClient={chatThreadClient}
                                previousMessage={previousMessage}
                                hasHistoryStatus={hasHistoryStatus}
                                threadId={threadId}
                                logId={logId}
                                autoJoinMeetingRoom={autoJoinMeetingRoom}
                                setAutoJoinMeetingRoom={setAutoJoinMeetingRoom}
                            />
                        </> :
                        <Loading/>
                    }
                </ChatThreadClientProvider>
            </ChatClientProvider>
        </FluentThemeProvider>
    )
}
export default ChatRoomByThreadId
