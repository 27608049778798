import {usePropsFor, MessageThread, SendBox} from '@azure/communication-react';
import React from 'react';

// import Loading from "./components/loading/loading";

function ChatComponents({previousMessage, extra}) {
    const messageThreadProps = usePropsFor(MessageThread);
    const sendBoxProps = usePropsFor(SendBox);

    return (
        <div>
            {/*Props are updated asynchronously, so only render the component once props are populated.*/}
            {/*{JSON.stringify(hasMessage)}*/}
            {/*{ previousMessage && previousMessage.length ? null : <Loading />}*/}
            <div
                style={{
                    top: '0px',
                    right: '0px',
                    zIndex: '1',
                    float: 'right',
                }}
            >
                {extra}
            </div>
            {messageThreadProps && <MessageThread {...messageThreadProps} messages={previousMessage}/>}
            {sendBoxProps && <SendBox {...sendBoxProps} />}
        </div>
    );
}

export default ChatComponents;
