import React from "react";
import {CommandBar} from "@fluentui/react";
import Loading from "../../loading/loading";

export default class IncomingCallCard extends React.Component {
    constructor(props) {
        super(props);
        this.incomingCall = props.incomingCall;
        this.acceptCallOptions = props.acceptCallOptions;
        this.acceptCallWithVideoOptions = props.acceptCallWithVideoOptions;
    }

    async componentWillMount() {
        this.acceptCallOptions = { videoOptions: (await this.acceptCallOptions()).videoOptions };
        this.acceptCallWithVideoOptions = { videoOptions: (await this.acceptCallWithVideoOptions()).videoOptions };
    }

    render() {
        return (
            <div className="ms-Grid mt-2">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-lg6">
                        <h2>Incoming Call...</h2>
                    </div>
                    {/*<div className="ms-Grid-col ms-lg6 text-right">*/}
                    {/*    {*/}
                    {/*        this.call &&*/}
                    {/*        <h2>Call Id: {this.state.callId}</h2>*/}
                    {/*    }*/}
                    {/*</div>*/}
                </div>
                <div className="custom-row">
                    <Loading/>
                </div>
                <div className="ms-Grid-row text-center">

                    <CommandBar
                        items={[
                            {
                                key: 'IncomingCall',
                                iconProps: {iconName: 'IncomingCall'},
                                iconOnly: true,
                                tooltipHostProps: {
                                    content: 'Answer call with video off'
                                },
                                onClick: () => this.incomingCall.accept(this.acceptCallOptions)
                            },
                            {
                                key: 'Video',
                                iconProps: {iconName: "Video"},
                                iconOnly: true,
                                tooltipHostProps: {
                                    content: 'Answer call with video on'
                                },
                                onClick: () => this.incomingCall.accept(this.acceptCallWithVideoOptions)
                            },
                            {
                                key: 'Reject call',
                                iconProps: {iconName: 'DeclineCall'},
                                iconOnly: true,
                                onClick: () => { this.incomingCall.reject(); this.props.onReject(); }
                            },
                        ]}
                        ariaLabel="Inbox actions"
                        primaryGroupAriaLabel="Email actions"
                        farItemsGroupAriaLabel="More actions"
                    />
                </div>
            </div>
        );
    }
}