import React, {useState} from 'react'
import {TextField} from '@fluentui/react/lib/TextField';

function TextFieldWithComposition({onSubmit, ...props}) {
    const [isEdit, setIsEdit] = useState(false)
    const [isSubmit, setIsSubmit] = useState(false)
    return (
        <TextField
            onCompositionStart={(e) => {
                console.log('composition start', e)
                setIsEdit(true)
            }}
            onCompositionEnd={(e) => {
                console.log('composition end', e)
                setIsEdit(false)
            }}
            onKeyDown={(e) => {
                if (e.keyCode === 13 && !isEdit) {
                    setIsSubmit(true)
                    onSubmit(e.target.value)
                }
            }}
            disabled={isSubmit}
            {...props}
         />
    )
}
export default TextFieldWithComposition