import React from "react";
import {utils} from '../Utils/Utils';
import {Persona, PersonaSize} from '@fluentui/react/lib/Persona';
import {Stack} from "@fluentui/react";
import {Icon} from '@fluentui/react/lib/Icon';

export default class RemoteParticipantCard extends React.Component {
    constructor(props) {
        super(props);
        this.call = props.call;
        this.remoteParticipant = props.remoteParticipant;
        this.id = utils.getIdentifierText(this.remoteParticipant.identifier);

        this.state = {
            isSpeaking: this.remoteParticipant.isSpeaking,
            state: this.remoteParticipant.state,
            isMuted: this.remoteParticipant.isMuted,
            displayName: this.remoteParticipant.displayName?.trim()
        };
    }

    async componentWillMount() {
        this.remoteParticipant.on('isMutedChanged', () => {
            this.setState({isMuted: this.remoteParticipant.isMuted});
            if (this.remoteParticipant.isMuted) {
                this.setState({isSpeaking: false});
            }
        });

        this.remoteParticipant.on('stateChanged', () => {
            this.setState({state: this.remoteParticipant.state});
        });

        this.remoteParticipant.on('isSpeakingChanged', () => {
            this.setState({isSpeaking: this.remoteParticipant.isSpeaking});
        })

        this.remoteParticipant.on('displayNameChanged', () => {
            this.setState({displayName: this.remoteParticipant.displayName?.trim()});
        })
    }

    handleRemoveParticipant(e, identifier) {
        e.preventDefault();
        this.call.removeParticipant(identifier).catch((e) => console.error(e))
    }

    render() {
        return (
            <div className={`participant-item`} key={utils.getIdentifierText(this.remoteParticipant.identifier)}>
                <div style={{position: 'relative'}}>
                    <Stack horizontal tokens={{
                        childrenGap: 5,
                        padding: 10,
                    }}>
                        <Stack.Item grow={22}>
                            <Persona className={this.state.isSpeaking ? `speaking-border-for-initials` : ``}
                                     size={PersonaSize.size40}
                                     text={this.state.displayName ?
                                         this.state.displayName :
                                         "User"
                                     }
                                     secondaryText={this.state.state}
                                     styles={{primaryText: {color: '#edebe9'}, secondaryText: {color: '#edebe9'}}}/>
                        </Stack.Item>
                        <Stack.Item grow={2} className="icon-text-large" align="center" >
                            {
                                this.state.isMuted &&
                                <Icon iconName="MicOff2"/>
                            }
                            {
                                !this.state.isMuted &&
                                <Icon iconName="Microphone"/>
                            }

                        </Stack.Item>
                    </Stack>
                    {/*<div style={{position: 'absolute', bottom: "0px", right: "0px"}}>*/}
                    {/*    <a href="#" onClick={e => this.handleRemoveParticipant(e, this.remoteParticipant.identifier)}*/}
                    {/*       className="participant-remove float-right ml-3">Remove participant</a>*/}
                    {/*</div>*/}

                </div>
            </div>
        )
    }
}



