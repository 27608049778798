import React from 'react'
import css from './loading.module.scss'

const Loading = () => {
   return (
       <div className={css.ldsRing}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
       </div>
   )
}
export default Loading
