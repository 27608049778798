import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';

import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { registerIcons } from '@fluentui/react/lib/Styling';
import {Icon} from "@fluentui/react/lib/Icon";
import ChatRoomByThreadId from "./chat-room-by-threadId";


initializeIcons();
registerIcons({
    icons: {
        sendboxsend: <Icon iconName={"Send"}/>,
        controlbuttoncameraoff: <Icon iconName={"Video"}/>,
        controlbuttoncameraon: <Icon iconName={"VideoOff"}/>,
        controlbuttonendcall: <Icon iconName={"DeclineCall"}/>,
        controlbuttonscreensharestart: <Icon iconName={"CircleStop"}/>,
        controlbuttonscreensharestop: <Icon iconName={"TVMonitor"}/>,
        controlbuttonmicoff: <Icon iconName={"Microphone"}/>,
        controlbuttonmicon: <Icon iconName={"MicOff2"}/>,
        controlbuttonoptions: <Icon iconName={"Settings"}/>,
        controlbuttonparticipants: <Icon iconName={"People"}/>,
    }
});
const App = () => {
    return (
        <Router>
            <Switch>
               <Route path="/:logId">
                    <ChatRoomByThreadId />
                </Route>
            </Switch>
        </Router>
    )
}
export default App
