import request from '../util/request'

const Customer = {
    domainUrl: process.env.REACT_APP_USER_API,
    version: '/v2',
    baseUrl: function () {
        return this.domainUrl + this.version;
    },
};
export const getCustomerInfo = () => {
    return request({
        url: '/customer/',
        baseURL: Customer.baseUrl(),
        method: 'get',
    });

}

