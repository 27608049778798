import request from '../util/request'

const Notify = {
    domainUrl: process.env.REACT_APP_NOFIFY_API,
    version: '/v1',
    baseUrl: function () {
        return this.domainUrl + this.version;
    },
};

export function fetchLogById(id) {
    return request({
        url: `/logs/${id}`,
        baseURL: Notify.baseUrl(),
        method: 'get',
    })
}
export const fetchUsersById = (logId) => {
    return request({
        url: `/logs/${logId}/users`,
        baseURL: Notify.baseUrl(),
        method: 'get',
    });

}
