import axios from "axios";
import { getToken } from "./auth";

// create an axios instance
const service = axios.create({
    baseURL: process.env.REACT_APP_BASE_API, // url = base url + request ur
    timeout: 10000, // request timeout
});

// request interceptor
service.interceptors.request.use(
    (config) => {
        // do something before request is sent

        if (getToken()) {
            config.headers["Authorization"] = "Bearer " + getToken();
            config.headers["Content-Type"] = "application/json";
        }
        return config;
    },
    (error) => {
        // do something with request error
        console.log(error); // for debug
        return Promise.reject(error);
    }
);

export default service
