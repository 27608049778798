import request from '../util/request'

const Notify = {
    domainUrl: process.env.REACT_APP_NOFIFY_API,
    baseUrl: function () {
        return this.domainUrl;
    },
};

export const testNotify = (data, userId) => {
    return request({
        url: '/push/users/' + userId,
        baseURL: Notify.baseUrl(),
        method: 'post',
        data,
    });

}
