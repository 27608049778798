import request from '../util/request'

const Notify = {
    domainUrl: process.env.REACT_APP_NOFIFY_API,
    version: '/v1',
    baseUrl: function () {
        return this.domainUrl + this.version;
    },
};
export const getUserById = ({
                                userId = ""
                            } = {}) => {
    return request({
        url: '/users/' + userId,
        baseURL: Notify.baseUrl(),
        method: 'get',
    });
}
